import React from 'react'
// @ts-ignore
import {Ripple} from 'react-awesome-spinners'

export function LoadingIndicator() {
    return (
        <div className='LoadingIndicator'>
            <Ripple color={'#4AA851'} />
        </div>
        )
}
