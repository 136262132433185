import React from 'react'
import './App.css'

export function UserHeader(props: any) {
    return (
        <div className='Header'>
            <span className='Email'>{props.email}</span>
            <button className='Link-button Link-button-inverse' onClick={props.logout}>Logout</button>
        </div>
    )
}
