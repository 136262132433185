import React from 'react'
import {FileItem} from './FileItem'

export function ListFiles(props: any) {
    const matchKey = (value: any) => {
        const pattern1 = `^${props.filter}`
        const pattern2 = '[^\\\\/]+$'
        const regex1 = new RegExp(pattern1, 'i')
        const regex2 = new RegExp(pattern2, 'i')
        return regex1.test(value.Key) && regex2.test(value.Key)
    }
    const extractFile = (value: string): (string | null) => {
        const pattern = '([^\\\\/]+)$'
        const regex = new RegExp(pattern, 'ig')
        const result = regex.exec(value)
        if (result === null) return null
        return result![0]
    }
    return (
        <div>
            {props.files
                .filter(matchKey)
                .map((fileObj: any, i: number) => {
                    return (<FileItem key={i} index={i} name={extractFile(fileObj.Key) || 'oops'} url={fileObj.secureUrl}/>)
                })
            }
        </div>
    )
}
