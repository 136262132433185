import React from 'react'
import {Section} from './Section'
import {SubSection} from './SubSection'
import {SubSubSection} from './SubSubSection'

export function LoggedInUI(props: any) {
    return (
        <div className='UIContainer'>
            <div className='OuterSpacer'/>
            <div className='ActualData'>
                <Section name={'Overview'}>
                    <SubSection name={'RGB'}>
                        <SubSubSection name={'Raw'} files={props.files} filter={'overview\/rgb\/raw\/'}/>
                        <SubSubSection name={'Processed'} files={props.files} filter={'overview\/rgb\/processed\/'}/>
                    </SubSection>
                    <SubSection name={'RGBi'}>
                        <SubSubSection name={'Raw'} files={props.files} filter={'overview\/rgbi\/raw\/'}/>
                        <SubSubSection name={'Processed'} files={props.files} filter={'overview\/rgbi\/processed\/'}/>
                    </SubSection>
                    <SubSection name={'GCP'} files={props.files} filter={'overview\/gcp\/'}/>
                </Section>
                <Section name={'Transects'}>
                    <SubSection name={'RGB'} files={props.files} filter={'transects\/rgb\/'}>
                        <SubSubSection name={'Raw'} files={props.files} filter={'transects\/rgb\/raw\/'}/>
                        <SubSubSection name={'Processed'} files={props.files} filter={'transects\/rgb\/processed\/'}/>
                    </SubSection>
                    <SubSection name={'RGBi'} files={props.files} filter={'transects\/rgbi\/'}>
                        <SubSubSection name={'Raw'} files={props.files} filter={'transects\/rgbi\/raw\/'}/>
                        <SubSubSection name={'Processed'} files={props.files} filter={'transects\/rgbi\/processed\/'}/>
                    </SubSection>
                </Section>
                <Section name={'Recreation'} files={props.files} filter={'recreation\/'}/>
                <Section name={'Cultural'} files={props.files} filter={'cultural\/'}/>
                <Section name={'Resources'} files={props.files} filter={'resources\/'}/>
            </div>
            <div className='OuterSpacer'/>
        </div>
    )
}
