import React, {useState} from 'react'
import {ListFiles} from './ListFiles'

export function SubSubSection(props: any) {
    const showFiles = typeof props.files !== 'undefined' && props.files.length > 0
    return (
        <>
            <div className='SpacerDiv'/>
            <div className='SubSubSection'>
                <span>{props.name}</span>
                <div>
                    { showFiles ? <ListFiles files={props.files} filter={props.filter}/> : props.children }
                </div>
            </div>
        </>
    )
}
