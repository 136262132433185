import axios from 'axios'
import * as firebase from 'firebase'
import React, {useEffect, useState} from 'react'
import './App.css'
import {ErrorMessage} from './ErrorMessage'
import {LoadingIndicator} from './LoadingIndicator'
import {LoggedInUI} from './LoggedInUI'
import {LoginForm} from './LoginForm'
import {UserHeader} from './UserHeader'

const API_ENDPOINT = 'https://us-central1-cutler-team.cloudfunctions.net/getBucketContents'
const loginError = 'Error occurred authenticating user'
const dataRetrievalError = 'It seems you do not have permission to view this data. Contact Scott at PMG Vegetation for access.'
const unknownServerError = 'An unknown error occurred'

// noinspection SpellCheckingInspection
const firebaseConfig = {
    apiKey: 'AIzaSyCaINcZcyw679PTRUwIX5DIB41z5KJCw04',
    authDomain: 'cutler-team.firebaseapp.com',
    databaseURL: 'https://cutler-team.firebaseio.com',
    projectId: 'cutler-team',
    storageBucket: 'cutler-team.appspot.com',
    messagingSenderId: '576018318199',
    appId: '1:576018318199:web:bcf017c95916008b945772',
    measurementId: 'G-GVJQJBXV6V',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

function App() {
    const [appInitializing, setAppInitializing] = useState(true)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<any | null>(null)
    const [token, setToken] = useState<string | null>(null)
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [fileList, setFileList] = useState<any[]>([])
    const clearErrorMessage = () => {
        setErrorMsg('')
    }
    const postErrorMessage = (error: string) => {
        setErrorMsg(error)
        // tslint:disable-next-line:no-console
        console.log(error)
    }
    const getUserToken = async (fbUser: any): Promise<string | null> => {
        let fbToken = null
        await fbUser.getIdToken(true)
            .then((userToken: string) => {
                fbToken = userToken
            })
        return fbToken
    }
    const login = async (username: string, password: string) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then( async () => {
                firebase.auth().signInWithEmailAndPassword(username, password)
                    .then( async () => {
                        await checkForUser()
                    })
                    .catch((error) => {
                        setUser(null)
                        setToken(null)
                        postErrorMessage(`${loginError}: ${error.message}`)
                    })
            })
            .catch((error) => {
                setUser(null)
                postErrorMessage(`${loginError}: ${error.message}`)
            })
    }
    const loadDataFromApi = async () => {
        setLoading(true)
        axios.get(API_ENDPOINT)
            .then((response) => {
                setLoading(false)
                setFileList(response.data)
                clearErrorMessage()
            }).catch((error) => {
                setLoading(false)
                setFileList([])
                if (error.message.includes('403')) {
                    postErrorMessage(dataRetrievalError)
                }
                else {
                    postErrorMessage(unknownServerError)
                }
            })
    }
    const checkForUser = async () => {
        if (firebase.auth().currentUser) {
            if (user !== null && firebase.auth().currentUser!.uid === user.uid && token !== null) return Promise.resolve()
            setUser(firebase.auth().currentUser)
            const fbToken = await getUserToken(firebase.auth().currentUser)
            setToken(fbToken)
            if (fbToken !== null) {
                await loadDataFromApi()
            }
        }
        else {
            setUser(null)
            setToken(null)
            setFileList([])
        }
    }
    const logout = () => {
        firebase.auth().signOut().then((r) => {
            setFileList([])
        })
    }
    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `bearer ${token !== null ? token : ''}`
    }, [token])
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(() => {
            // noinspection JSIgnoredPromiseFromCall
            checkForUser()
        })
        // noinspection JSIgnoredPromiseFromCall
        checkForUser()
        setAppInitializing(false)
        return unsubscribe
    }, [user])
    return (
        <div className='App'>
            <header className='App-header'>
                <p>
                    Cutler Team File Sharing
                </p>
            </header>
            <div>
                { appInitializing
                    ? <LoadingIndicator/>
                    : (user === null)
                            ? (<LoginForm postError={postErrorMessage} login={login}/>)
                            : ( loading
                                ? <LoadingIndicator/>
                                : ( <>
                                        <UserHeader email={user.email} logout={logout}/>
                                        { errorMsg === ''
                                            ? <LoggedInUI files={fileList} logout={logout} email={user!.email} />
                                            : <ErrorMessage message={errorMsg}/>
                                        }
                                    </>)
                            )
                }
            </div>
        </div>
    )
}

export default App
