import React, {useState} from 'react'

export function ErrorMessage(props: any) {
    return (
        <div className='ErrorContainer'>
            <div className='ErrorSpacer'/>
            <div className='ErrorMessage'>
                <span>{props.message}</span>
            </div>
            <div className='ErrorSpacer'/>
        </div>
    )
}
