import React, {useState} from 'react'

export function LoginForm(props: any) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const login = (event: any) => {
        event.preventDefault()
        props.login(username, password)
    }
    return (
        <div className='LoginContainer'>
                <div className='LoginSpacer' />
            <div className='LoginForm'>
                <form onSubmit={login}>
                <div className='LoginField'>
                    <label>Username: </label>
                    <input className='LoginInput' type={'text'} onInput={(e: any) => setUsername(e.target.value)}/>
                </div>
                <div className='LoginField'>
                    <span>Password: </span>
                    <input className='LoginInput' type={'password'} onInput={(e: any) => setPassword(e.target.value)}/>
                </div>
                <button type='submit' className='LoginButton' onClick={login}>Login</button>
                </form>
            </div>
            <div className='LoginSpacer' />
        </div>
    )
}
