import React, {useState} from 'react'
import {ListFiles} from './ListFiles'
import logo from './logo.svg'
export function Section(props: any) {
    const showFiles = typeof props.files !== 'undefined' && props.files.length > 0
    return (
        <div className='Section'>
            <div>
                <div className='SectionLabel'>
                    <span>{props.name}</span>
                    {/*<img src={logo} className='App-logo' alt={'folder'} />*/}
                </div>
                <div>
                    { showFiles ? <ListFiles files={props.files} filter={props.filter}/> : props.children }
                </div>
            </div>
        </div>
    )
}
